import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function About() {
  useEffect(() => {
    AOS.init({
      duration: 1000, 
      easing: "ease-in-out", 
      once: false,
      mirror: true,
    });
  }, []);

  return (
    <section
      className="about-section relative w-full flex flex-col lg:flex-row items-center justify-center pt-16 pb-4 bg-cover bg-center bg-no-repeat bg-fixed min-h-[120vh]"
      style={{ backgroundImage: "url('/About_Background.jpg')" }}
    >
      <div className="relative max-w-[1200px] mx-auto flex flex-col lg:flex-row justify-between items-center px-6 lg:px-4">
        {/* Text Content Container */}
        <div className="mt-0 lg:mt-[-200px] flex-basis-[100%] lg:flex-basis-[60%] text-left font-light relative">
          <h3 className="font-notoserif text-3xl lg:text-4xl leading-tight mt-0 mb-4 text-[#1d1d1d] relative">
            <em className="relative inline-block">
              <span className="underline decoration-[#E1A291] decoration-[2px] underline-offset-[4px]">
                "I know
              </span>
            </em>{" "}
            how it feels to be&nbsp;
            <span className="inline-block text-5xl lg:text-7xl font-bird-and-thorn text-[#9D646B] font-light ml-1 translate-y-3">
              you.
            </span>
          </h3>

          <blockquote className="w-full lg:w-[90%] mt-6 text-[#333] text-base lg:text-lg leading-relaxed font-notoserif font-light pl-0 lg:pl-10 max-w-[700px] mb-10 lg:mb-0">
            <p>
              A dancer, grinding long hours in the studio, rehearsing,
              auditioning, competing, living, breathing & dreaming dance.
            </p>
            <p className="mt-4">
              At 19 years old I suffered a low back injury that doctors deemed
              career-ending. I learned the hard way, the consequences of not
              taking self-care or proper cross-training seriously. Yoga nurtured
              me back to health, back to dancing and into the best shape of my
              life - all without a single pill, surgery, diet or gym!
            </p>
          </blockquote>

          {/* Gold Line Image */}
          <img
            id="gold-line"
            data-aos="fade-right"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-once="false"
            src="/About_Line.webp"
            alt="Gold Line"
            className="block lg:absolute bottom-[10px] lg:bottom-[-150px] left-0 w-full max-w-[300px] lg:max-w-[350px] ml-0 lg:ml-10"
          />
        </div>

        {/* Right Image Content and Text Underneath */}
        <div className="flex flex-col items-center mt-10 lg:mt-0">
          {/* Right Image with AOS Animation */}
          <div
            data-aos="fade-in"
            data-aos-anchor-placement="top-bottom"
            data-aos-once="false"
            className="relative z-10 lg:top-[-200px] lg:right-[-20px]"
          >
            <img
              src="/About_Image.webp"
              alt="Dancer"
              className="block w-full max-w-[250px] lg:max-w-[500px] rounded-full"
            />
          </div>

          <div className="mt-6 lg:mt-[-150px] text-left text-[#1d1d1d] font-notoserif font-light text-sm lg:text-lg max-w-[300px] lg:max-w-[700px] leading-relaxed pr-0 lg:pr-10">
            <p>
              So, I've put together a program to share my signature methodology
              from everything that has allowed me to come back from a
              “career-ending” injury. To teach dancers everything I wish I had
              learned sooner to prevent injury & THRIVE as an artist.
            </p>

            <p className="mt-4 italic font-light text-xl lg:text-2xl">
              You deserve that kind of support to make your dreams a reality"
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
