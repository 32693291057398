import React from "react";

function Hero() {
  return (
    <section
      className="relative w-full bg-cover bg-center flex items-center justify-center pt-16 pb-32 min-h-[90vh] bg-fixed"
      style={{ backgroundImage: "url('/HeroD_Background.jpg')" }}
    >
      <div className="container mx-auto px-6 flex flex-col md:flex-row items-center justify-between">
        {/* Left Image */}
        <div className="w-full md:w-1/2 flex justify-center md:justify-start">
          <div className="relative w-[90%] max-w-[550px] mx-auto md:ml-auto md:mr-0">
            <img
              src="/HeroD.webp"
              alt="Dancer"
              className="block max-w-full h-auto border-0"
            />
          </div>
        </div>

        {/* Right Text Content */}
        <div className="w-full md:w-1/2 text-center md:text-left mt-10 md:mt-0">
          {/* Container for Text Elements */}
          <div className="max-w-[570px] mx-auto">
            <h1 className="font-notoserif font-light leading-tight mt-0 mb-2 text-[#1d1d1d] text-[40px] md:text-[56px]">
              Holistically Helping Dancers{" "}
              <span className="text-[#1F1E1E]">level up their</span>
            </h1>
            <h2 className="font-medium text-[#9D646B] text-[18px] md:text-[20px] mt-2 mb-4 tracking-widest">
              TECHNIQUE, HEALTH & CONFIDENCE FOR LONG FULFILLING CAREERS!
            </h2>
            <p className="text-lg text-gray-700 mt-4">
              Be at the top of your game without sacrificing your mental and
              physical health!
            </p>
            <a
              href="#"
              className="inline-block bg-gradient-to-r from-yellow-400 to-yellow-600 text-white text-lg py-3 px-8 mt-6 shadow-lg transition duration-300 ease-in-out hover:shadow-xl rounded-xl font-light"
            >
              LEARN MORE
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
