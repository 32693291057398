import React from "react";

const services = [
  {
    title: "Mobility Muse",
    subtitle: "MASTERCLASS ARCHIVES",
    description:
      "My 8-Week Yoga Cross Training + Pole Dancing series. A potent movement lab to unlock impressive new shapes & dance confidently with your inner strong & flexible creative muse!",
    image: "/Mobility_Muse.webp",
    buttonLabel: "LEARN MORE",
    link: "/resource_redirect/landing_pages/2150361013",
  },
  {
    title: "The Flexibility",
    subtitle: "MASTERCLASS",
    description:
      "The complete guide to the most effective stretching routines & key techniques for improving flexibility.",
    image: "Flexibility_Masterclass.webp",
    buttonLabel: "REGISTER HERE",
    link: "/flexibility-master-class",
  },
  {
    title: "Terra",
    subtitle: "YOGA RETREAT",
    description:
      "Join me for this immersive grounded goddess retreat in California Redwoods.",
    image: "Terra.webp",
    buttonLabel: "RETREAT DETAILS",
    link: "/resource_redirect/landing_pages/2150081770",
  },
];

const Services = () => {
  return (
    <section
      className="relative bg-cover bg-center py-16"
      style={{
        backgroundImage: `url('/Services_Background.jpg')`,
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
      }}
    >
      {/* Overlay */}
      <div className="absolute inset-0 bg-[#efdbd2] opacity-60 section__overlay"></div>

      {/* Content */}
      <div className="relative container mx-auto text-center">
        {/* Section Title */}
        <h2 className="font-notoserif font-thin text-h2-custom mt-h2-top mb-h2-bottom text-gray-800">
          Ways To Work
        </h2>
        <h4 className="font-bird-and-thorn text-h4-custom text-[#9D646B] font-light mt-4">
          With Me
        </h4>

        {/* Services Grid */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12 mt-12">
          {services.map((service, index) => (
            <div
              key={index}
              className="group relative transition transform hover:scale-105 duration-500"
              style={{ backgroundColor: "transparent" }} 
              data-aos="fade-up"
              data-aos-delay={index === 0 ? "0" : index === 1 ? "400" : "200"} 
              data-aos-duration="1000"
            >
              <a href={service.link}>
                <div className="relative">
                  <img
                    className="w-full h-auto object-cover feature__image mb-4 transition-opacity duration-300 group-hover:opacity-80"
                    src={service.image}
                    alt={service.title}
                    style={{ borderRadius: "8px" }}
                  />
                </div>
              </a>
              <div className="text-center">
                {/* Applying the same font classes as 'Ways to Work' to h3 */}
                <h3 className="font-notoserif font-thin text-h2-custom text-gray-800 mb-2">
                  {service.title}
                </h3>
                <p className="text-[#9d646b] font-raleway font-thin text-center text-2xl tracking-widest uppercase mb-4">
                  {service.subtitle}
                </p>
                <p className="text-gray-600 font-montserrat mb-6">
                  {service.description}
                </p>

                {/* Apply exact button styling */}
                <a
                  href={service.link}
                  className="inline-block bg-gradient-to-r from-yellow-400 to-yellow-600 text-white text-lg py-3 px-8 mt-6 shadow-lg transition duration-300 ease-in-out hover:shadow-xl rounded-xl font-light"
                >
                  {service.buttonLabel}
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
