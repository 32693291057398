import React from "react";
import Hero from "../components/Hero";
import About from "../components/About";
import Services from "../components/Services";
import Youtube from "../components/Youtube";
import Testimonial from "../components/Testimonial";

function Home() {
  return (
    <div>
      <Hero />
      <About />
      <Services />
      <Youtube />
      <Testimonial />
    </div>
  );
}

export default Home;
