import React from "react";
import Slider from "react-slick";

const testimonials = [
  {
    text: (
      <>
        “YDA has{" "}
        <span className="font-bold italic">
          boosted my confidence in my flexibility.
        </span>{" "}
        I always worked so hard at my flexibility for years and saw little
        progress. But Roxie has taught me how to strengthen my body and the
        importance of active vs. passive stretching, which{" "}
        <span className="font-bold italic">
          I’ve noticed immediate results from
        </span>
        . It’s also boosted my confidence as a human to have the tools and
        capability to work through anything that is hindering my growth.”
      </>
    ),
    name: "Nadia",
  },
  {
    text: (
      <>
        “My back is so strong and mobile, I've been doing the videos everyday
        and honestly{" "}
        <span className="font-bold italic">
          I feel the best I've felt in years
        </span>
        !”
      </>
    ),
    name: "Maya",
  },
  {
    text: (
      <>
        “I've been doing the IT Band class 1-2 times a week and{" "}
        <span className="font-bold italic">
          my knees and body feel AMAZING!!! That really was a game changer
        </span>
        , I engaged my body in so many ways that I had taken for granted
        before.”
      </>
    ),
    name: "Justine",
  },
  {
    text: (
      <>
        “In dance going over foundational alignment has boosted my confidence so
        that I can push myself physically, and{" "}
        <span className="font-bold italic">
          I was so surprised by how quickly and effectively those changes
          happened.
        </span>
        ”
      </>
    ),
    name: "Jordan",
  },
  {
    text: (
      <>
        “YDA is the best gift I’ve ever given myself as a dancer!{" "}
        <span className="font-bold italic">
          It’s allowed me to explore dreams I didn’t know I had, heal places
          within me I didn’t know needed healing
        </span>
        , and I feel so equipped to take care of my physical self.”
      </>
    ),
    name: "Chelsea",
  },
  {
    text: (
      <>
        “No joke,{" "}
        <span className="font-bold italic">
          your classes have helped my technique a ton!
        </span>
        ”
      </>
    ),
    name: "Julie",
  },
];

const Testimonial = () => {
  const settings = {
    dots: true, // Enable dots
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    customPaging: (i) => (
      <button>
        <span className="slick-dot-icon">
          <i className="fas fa-circle"></i>
        </span>
      </button>
    ), // Custom button for dots
  };

  return (
    <section
      className="relative bg-white py-16"
      style={{
        backgroundImage: "url('/Testimonial_Background.jpg')", // Background image
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {/* Gold Line at the Start */}
      <div className="flex justify-center w-full mb-8">
        <img
          src="/gold_line_down.png"
          alt="Gold Line"
          className="w-[4px] h-[200px] relative -mt-40"
          data-aos="fade-down"
          data-aos-duration="1000"
        />
      </div>

      {/* Testimonial Content */}
      <div className="container mx-auto text-center">
        {/* Main heading */}
        <h2 className="font-notoserif font-thin text-4xl lg:text-5xl text-gray-800 mb-2">
          What other dancers
        </h2>

        {/* Secondary text */}
        <h4
          className="font-bird-and-thorn text-center"
          style={{
            color: "#d88f96",
            lineHeight: "50px",
            fontSize: "70px",
            letterSpacing: "normal",
            textRendering: "optimizeLegibility",
          }}
        >
          are saying
        </h4>

        {/* carousel */}
        <div className="mt-24">
          <Slider {...settings}>
            {testimonials.map((testimonial, index) => (
              <div
                key={index}
                className="flex flex-col items-center text-center space-y-2 w-3/4"
              >
                <div className="w-full flex justify-center mb-2">
                  <img
                    src="/gold-quote.webp"
                    alt="Gold Quote"
                    className="w-[35px]"
                  />
                </div>
                <p className="text-lg text-gray-600 leading-relaxed max-w-3xl mx-auto">
                  {testimonial.text}
                </p>
                <h5 className="font-notoserif font-thin text-2xl mt-4 text-gray-700">
                  - {testimonial.name}
                </h5>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
