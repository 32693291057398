import React from "react";

const Youtube = () => {
  return (
    <section
      className="flex flex-col md:flex-row items-center justify-between px-8 py-16 md:pb-40"
      style={{
        backgroundImage: `url('/Youtube_Background.jpg')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {/* Image Section */}
      <div className="md:w-1/2 flex justify-center">
        <img
          className="w-[500px] rounded-lg shadow-lg object-cover"
          src="/Youtube.webp"
          alt="Yoga class on laptop"
        />
      </div>

      {/* Text Section */}
      <div className="md:w-1/2 mt-8 md:mt-0 text-center md:text-left">
        {/* H2 */}
        <h2 className="font-notoserif text-3xl lg:text-4xl leading-tight mt-0 mb-4 text-[#1d1d1d] relative">
          <em className="relative inline-block">
            <span className="underline decoration-[#E1A291] decoration-[2px] underline-offset-[4px]">
              "Subscribe
            </span>
          </em>{" "}
          to my YouTube Channel.
        </h2>

        {/* P elements */}
        <blockquote className="w-full mt-6 text-[#333] text-base lg:text-lg leading-relaxed font-notoserif font-light pl-0 lg:pl-10 max-w-[700px] mb-10">
          <p>
            Come take a yoga class with me! Whether you want to unlock your true
            flexibility potential, build powerhouse strength, future-proof your
            body from injuries, revitalize your nervous system, or just relieve
            soreness & chronic pain, I have a class for it all!
          </p>
          <p className="mt-4">
            All the yoga for dancers classes are created with the specific needs
            and movements of dancers in mind, rooted in healthy alignment
            fundamentals and solutions for bio-hacking your unique body code to
            reach your goals and feel your best!
          </p>
        </blockquote>

        {/* Subscribe Button */}
        <a
          href="#"
          className="inline-block bg-gradient-to-r from-yellow-400 to-yellow-600 text-white text-lg py-3 px-8 shadow-lg transition duration-300 ease-in-out hover:shadow-xl rounded-xl font-light"
        >
          SUBSCRIBE NOW
        </a>
      </div>
    </section>
  );
};

export default Youtube;
