import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./pages/Navbar";
import Home from "./pages/Home";
import About from "./pages/About";
import FlexibilityMasterclass from "./pages/FlexibilityMasterclass";
import MobilityMuse from "./pages/MobilityMuse";
import Resources from "./pages/Resources";
import Login from "./pages/Login";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route
          path="/flexibility-masterclass"
          element={<FlexibilityMasterclass />}
        />
        <Route path="/mobility-muse-masterclasses" element={<MobilityMuse />} />
        <Route path="/resources" element={<Resources />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </Router>
  );
}

export default App;
